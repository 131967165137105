/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { Link } from "gatsby"
// import { useStaticQuery, graphql } from "gatsby"

const Sidebar = props => {
  //   const sidebarItems = data.allStrapiPages

  return (
    <>
      <ul className="nav flex-column">
        <li className="font-weight-bold">Features</li>
        {props.items.map(item => {
          return (
            <>
              <li className="nav-item" key={item.node.Slug}>
                <Link
                  className="nav-link d-inline-block"
                  to={`/${item.node.Slug}`}
                >
                  {item.node.PageTitle}
                </Link>
              </li>
            </>
          )
        })}
      </ul>
    </>
  )
}

export default Sidebar
