/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"
import Sidebar from "./sidebar"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      allStrapiPages {
        edges {
          node {
            PageTitle
            Slug
          }
        }
      }
    }
  `)

  return (
    <>
      <link
        rel="stylesheet"
        href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
        integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
        crossOrigin="anonymous"
      ></link>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div>
        <main>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-2 sidebar-menu">
                <Sidebar items={data.allStrapiPages.edges} />
              </div>
              <div className="col-md-6 content">{children}</div>
            </div>
          </div>
        </main>
        <footer
          style={{
            marginTop: `2rem`,
          }}
        >
          <div className="container-fluid">
            &copy; {new Date().getFullYear()}, Built by
            {` `}
            <a href="mailto:sfh.noreply@gmail.com">Bayu Anggara</a>
          </div>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
